*{
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
body{
  background: #1d1d1e !important;
  width: 100%;
  min-height: 100vh;

}
.whiteShadow{
  box-shadow: 0px 0px 5px white;
}