*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}
.bg-nav{
    background: #1d1d1e;
}
p,li{
    font-size: 18px;
}
.nav-link{
    font-size: 16px;
    color: white;
    
}
#ClickCount{
    color: #33CC99;
    font-size: 90px;
    font-weight: bold;
    margin-top: 0px;
    text-shadow: 2px 0 1px #000, 0 1px 1px #000, -1px 0 1px #000, 0 -1px 1px #000;
}
#ClickResultInfo{
    color: #fff;
    font-size: 26px;
    margin-bottom: 35px;
    font-weight: bold;
}
#ClickButton{
  
    width: 45%;
    height: 280px;
    min-width:300px;
    background: #000;
    border-radius: 10px;
    cursor: pointer;
    font-size: 24px;
    font-weight: bold;
    color: #fff;
    box-shadow: 1px 1px 5px white;

    
   

}

#ClearClicksResult{
    background: linear-gradient(to bottom, #FFD700 0%,#FF8C00 100%);
    color: #fff;
    font-size: 16px;
    border: 0px solid #fff;
    border-radius: 5px;
    cursor: pointer;
    user-select: none;
    font-weight: bold;
    width: 100px;
    height: 50px;
   

}

#restartMaxScore{
    background: linear-gradient(to bottom, #97d624 0%,#7cbc0a 100%);
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    user-select: none;
    border: 0px solid #fff;
    border-radius: 5px;
    width: 200px;
    height: 50px;
   

}

#StopClicks{
    background: linear-gradient(to bottom, #ff3019 0%,#cf0404 100%);
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    user-select: none;
    border: 0px solid #fff;
    border-radius: 5px;
    width: 100px;
    height: 50px;
   

}
.rounded-circle{
 height: 40px !important;
 width: 40px !important;
}
.footer{
    background: rgb(5, 3, 3);
}
.boxshadow{
    box-shadow: 0px 0px 5px white;

}
.borderReadius10{
    border-radius: 10px;
}
.card{
    background: #1d1d1e !important;
    cursor: pointer;
}
.card:hover{
    background: #000 !important;
}
.border1px{
    border: 5px solid red;
}

.bg-banner{
    background: #33CC99;
}
.wordSpacing{
    word-spacing:1px;
}
.stepBorder{
    border: 1px solid #666666;
}
.stepCard{
    min-height: 180px !important;
}


@media screen and (max-width: 768px) {
   .mainLogo{
       width: 200px;
       height: 30px;
   }
   #finalMaxScore{
       font-size: 16px;
   }
   #timerSpan{
    font-size: 18px;

   }
   .otherToolImg{
       width: 200px;
       height: 200px;
   }
  }